import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  HelpBlock,
  Alert,
  ControlLabel,
} from "@freecodecamp/ui";
import type { TFunction } from "i18next";
import { withTranslation } from "react-i18next";
import isURL from "validator/lib/isURL";

import { FullWidthRow, Spacer } from "../helpers";
import BlockSaveButton from "../helpers/form/block-save-button";
import type { CamperProps } from "../profile/components/camper";
import SoundSettings from "./sound";
import ThemeSettings, { type ThemeProps } from "./theme";
import UsernameSettings from "./username";
import KeyboardShortcutsSettings from "./keyboard-shortcuts";
import SectionHeader from "./section-header";
import ScrollbarWidthSettings from "./scrollbar-width";

type AboutProps = ThemeProps &
  Omit<
    CamperProps,
    | "linkedin"
    | "joinDate"
    | "isDonating"
    | "githubProfile"
    | "twitter"
    | "website"
    | "yearsTopContributor"
  > & {
    sound: boolean;
    keyboardShortcuts: boolean;
    submitNewAbout: (formValues: FormValues) => void;
    t: TFunction;
    toggleSoundMode: (sound: boolean) => void;
    toggleKeyboardShortcuts: (keyboardShortcuts: boolean) => void;
  };

type FormValues = Pick<AboutProps, "name" | "location" | "picture" | "about" | "cohort">;

type AboutState = {
  formValues: FormValues;
  originalValues: FormValues;
  formClicked: boolean;
  isPictureUrlValid: boolean;
};

const ShowImageValidationWarning = ({
  alertContent,
}: {
  alertContent: string;
}) => {
  return (
    <HelpBlock>
      <Alert variant="info">{alertContent}</Alert>
    </HelpBlock>
  );
};

class AboutSettings extends Component<AboutProps, AboutState> {
  validationImage: HTMLImageElement;
  static displayName: string;

  constructor(props: AboutProps) {
    super(props);
    this.validationImage = new Image();

    // Retrieve form values from localStorage, or use props as fallback
    const savedValues = JSON.parse(
      localStorage.getItem("aboutFormValues") || "{}"
    );
    const {
      name = "",
      location = "",
      picture = "",
      about = "",
      cohort = "", // Changed from preferredColor to cohort
    } = { ...props, ...savedValues };

    this.state = {
      formValues: { name, location, picture, about, cohort },
      originalValues: { name, location, picture, about, cohort },
      formClicked: false,
      isPictureUrlValid: true,
    };
  }

  componentDidUpdate() {
    const { name, location, picture, about, cohort } = this.props;
    const { formValues, formClicked } = this.state;
    if (
      formClicked &&
      name === formValues.name &&
      location === formValues.location &&
      picture === formValues.picture &&
      about === formValues.about &&
      cohort === formValues.cohort
    ) {
      return this.setState({
        originalValues: { name, location, picture, about, cohort },
        formClicked: false,
      });
    }
    return null;
  }

  isFormPristine = () => {
    const { formValues, originalValues } = this.state;
    return (
      this.state.isPictureUrlValid === false ||
      (Object.keys(originalValues) as Array<keyof FormValues>).
        map((key) => originalValues[key] === formValues[key]).
        every((bool) => bool)
    );
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { formValues } = this.state;
    const { submitNewAbout } = this.props;
    if (this.state.isPictureUrlValid === true && !this.isFormPristine()) {
      this.setState({ formClicked: true }, () => submitNewAbout(formValues));
      // Save to localStorage on form submit
      localStorage.setItem("aboutFormValues", JSON.stringify(formValues));
    } else {
      return false;
    }
  };

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.updateFormValues({ name: value });
  };

  handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.updateFormValues({ location: value });
  };

  handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isURL(value, { require_protocol: true })) {
      this.validationImage.src = encodeURI(value);
    } else {
      this.setState({ isPictureUrlValid: false });
    }
    this.updateFormValues({ picture: value });
  };

  handleAboutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.updateFormValues({ about: value });
  };

  handleCohortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.updateFormValues({ cohort: value });
  };

  updateFormValues = (newValues: Partial<FormValues>) => {
    this.setState(
      (state) => ({
        formValues: { ...state.formValues, ...newValues },
      }),
      () => {
        // Save the updated formValues to localStorage after each change
        localStorage.setItem("aboutFormValues", JSON.stringify(this.state.formValues));
      }
    );
  };

  componentDidMount() {
    const { username } = this.props;
    this.validationImage.addEventListener("error", this.errorEvent);
    this.validationImage.addEventListener("load", this.loadEvent);
    
    // Stocker le nom d'utilisateur dans le localStorage lors de l'accès initial
    if (username) {
      localStorage.setItem('username', username);
    }
  }

  componentWillUnmount() {
    this.validationImage.removeEventListener("load", this.loadEvent);
    this.validationImage.removeEventListener("error", this.errorEvent);
  }

  loadEvent = () => this.setState({ isPictureUrlValid: true });
  errorEvent = () =>
    this.setState((state) => ({
      isPictureUrlValid: state.formValues.picture === "",
    }));

  render() {
    const {
      formValues: { name, location, picture, about, cohort },
    } = this.state;
    const {
      currentTheme,
      sound,
      keyboardShortcuts,
      username,
      t,
      toggleNightMode,
      toggleSoundMode,
      toggleKeyboardShortcuts,
    } = this.props;

    return (
      <>
        <UsernameSettings username={username} />
        <Spacer size="medium" />
        <SectionHeader>{t("settings.headings.personal-info")}</SectionHeader>
        <FullWidthRow>
          <form
            id="camper-identity"
            onSubmit={this.handleSubmit}
            data-playwright-test-label="camper-identity"
          >
            <div role="group" aria-label={t("settings.headings.personal-info")}>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup controlId="about-name">
                    <ControlLabel htmlFor="about-name-input">
                      <strong>{t("settings.labels.name")}</strong>
                    </ControlLabel>
                    <input
                      onChange={this.handleNameChange}
                      type="text"
                      value={name}
                      id="about-name-input"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup controlId="about-location">
                    <ControlLabel htmlFor="about-location-input">
                      <strong>{t("settings.labels.location")}</strong>
                    </ControlLabel>
                    <input
                      onChange={this.handleLocationChange}
                      type="text"
                      value={location}
                      id="about-location-input"
                    />
                  </FormGroup>
                </div>
              </div>

              <FormGroup controlId="about-about">
                <ControlLabel htmlFor="about-about-input">
                  <strong>{t("settings.labels.about")}</strong>
                </ControlLabel>
                <textarea
                  onChange={this.handleAboutChange}
                  type="text"
                  value={about}
                  id="about-about-input"
                />
              </FormGroup>

              {/* New cohort input */}
              <FormGroup controlId="about-cohort">
                <ControlLabel htmlFor="about-cohort-input">
                  <strong>cohort</strong>
                </ControlLabel>
                <input
                  onChange={this.handleCohortChange}
                  type="text"
                  value={cohort}
                  id="about-cohort-input"
                  placeholder="cohort"
                />
              </FormGroup>
            </div>

            <BlockSaveButton
              disabled={this.isFormPristine()}
              onClick={this.handleSubmit}
              text={t("settings.buttons.save")}
            />
          </form>
        </FullWidthRow>
      </>
    );
  }
}

AboutSettings.displayName = "AboutSettings";
export default withTranslation()(AboutSettings);
